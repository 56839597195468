<template lang="pug">
div
  fi-data-field(:label = '$t("reportingType")' :label-cols = 3 ) {{ classifierById('TYP_PRTCTN', productData.assetReportingType).human }}
  .flex-row-direction
    .loan-collateral-asset-product-fields-display.animated.fadeIn.width-50.margin-right-20
      fi-data-field(:label = '$t("description")') {{ productData.description }}
      fi-data-field(:label = '$t("assetGroup")') {{ assetGroup.human }}
      fi-data-field(:label = '$t("vehicleBrand")') {{ productData.vehicleBrand }}
      fi-data-field(:label = '$t("vehicleModel")') {{ productData.vehicleModel }}
      fi-data-field(:label = '$t("vehicleProductionYear")') {{ productData.vehicleProductionYear }}
      fi-data-field(:label = '$t("sellerId")') {{ customerName(seller) }}
      fi-data-field(:label = '$t("sellerContactName")') {{ productData.sellerContactName }}
      fi-data-field(:label = '$t("sellerPhone")') {{ productData.sellerPhone }}
      fi-data-field(:label = '$t("sellerEmail")') {{ productData.sellerEmail }}
      fi-data-field(:label = '$t("postalСode")') {{ productData.warrantyPostalCode }}
      fi-data-field(:label = '$t("warrantyOwnerName")')
        .flex-column-direction
          <table v-if = 'productData.assetsOwners' style="width:100%">
            template(v-for = '(owner, index) in productData.assetsOwners')
              <tr>
                <td class = 'bold' style="border:none, padding:0px"> {{ owner.customerName }} </td>
              </tr>
          </table>
      fi-data-field(:label = '$t("assetInfo")')
        .text(v-html = 'sanitizedText')
      fi-data-field(:label = '$t("internalComment")')
        .text(v-html = 'sanitizedCommentText')
    div.width-50
      fi-data-field(:label = '$t("valueType")') {{ classifierById('TYP_PRTCTN_VL', productData.valueType).human }}
      fi-data-field(:label = '$t("valuationType")') {{ classifierById('PRTCTN_VLTN_APPRCH', productData.valuationType).human }}
      fi-data-field(:label = '$t("locationCode")') {{ classifierById('ISO3166_NUTS_DSJNT', productData.collateralLocationCode).human }}
      fi-data-field(:label = '$t("assetStatus")') {{ productData.assetStatus }}
      //- fi-data-field(:label = '$t("appraisalPartner")') {{ customerName(appraisalPartnerData) }}
      fi-data-field(:label = '$t("assetSoldValue")') {{ productData.assetSoldValue }}
      fi-data-field(:label = '$t("soldDate")') {{ $t('common:formatDate', { value: productData.soldDate }) }}
      fi-data-field(:label = '$t("warrantyArea")')
        .row
          .col {{ productData.warrantyArea }}
          .col {{ classifierById('immovableAreaSizeType', productData.areaSizeTypeId).human }}
      fi-data-field(:label = '$t("value")') {{ assetData.value | money }}
      fi-data-field(:label = '$t("valueDate")') {{ $t('common:formatDate', { value: assetData.valueDate }) }}
      fi-data-field(:label = '$t("allocatedValue")') {{ assetData.allocatedValue | money }}
      fi-data-field(:label = '$t("appraisalReport")') {{ assetData.appraisalReport ? $t('common:yes') : $t('common:no') }}
      template(v-if = 'assetData.appraisalReport')
        fi-data-field(:label = '$t("appraisalPartnerName")') {{ assetData.appraisalPartnerName }}
        fi-data-field(:label = '$t("appraisalValue")') {{ assetData.appraisalValue }}
      fi-data-field(:label = '$t("insuranceMandatory")') {{ assetData.insuranceMandatory ? $t('common:yes') : $t('common:no') }}
      fi-data-field(:label = '$t("insuranceCompany")') {{ assetData.insuranceCompany }}
      fi-data-field(:label = '$t("insuredUntil")') {{ $t('common:formatDate', { value: assetData.insuredUntil }) }}
</template>


<script>
import FiDataField from '@/components/FiDataField'
import sanitizeHtml from 'sanitize-html'
import { mapGetters } from 'vuex'
import { customerName } from '@/helpers'

export default {
  name: 'loan-collateral-asset-product-fields-display',

  components: { FiDataField },

  props: {
    productData: {
      type: Object,
      required: true
    },
    assetData: {
      type: Object,
      required: true
    },
    seller: {
      type: Object,
      default: null
    },
    appraisalPartnerData: {
      type: Object,
      default: null
    }
  },

  i18nOptions: {},

  computed: {
    ...mapGetters('classifiers', ['classifierByName', 'classifierById']),
    sanitizedText () {
      return sanitizeHtml(this.assetData.assetInfo || '', {
        allowedTags: false,
        allowedAttributes: false,
        allowedSchemes: ['data', 'http'],
        nonTextTags: ['style', 'script', 'textarea', 'noscript']
      })
    },
    sanitizedCommentText () {
      return sanitizeHtml(this.assetData.comment || '', {
        allowedTags: false,
        allowedAttributes: false,
        allowedSchemes: ['data', 'http'],
        nonTextTags: ['style', 'script', 'textarea', 'noscript']
      })
    },
    assetGroup () {
      const { assetGroup } = this.productData
      return this.classifierByName('assetGroup', assetGroup) ?? {}
    }
  },
  methods: {
    customerName
  }
}
</script>

<style lang="scss" scoped>
  .flex-row-direction {
    display: flex;
  }
  .flex-column-direction {
    display: flex; flex-direction: column;
  }
  .bold {
    font-weight: bold;
    border: none !important;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .justify-content-space-evenly {
    justify-content: space-evenly;
  }
  .width-50 {
    width: 50%;
  }
  .margin-right-20 {
    margin-right: 20px
  }
  </style>

<i18n>
en:
  description:           "Description"
  assetGroup:            "Asset group"
  value:                 "Value"
  valueDate:             "Value date"
  allocatedValue:        "Allocated value"
  vehicleBrand:          "Brand"
  vehicleModel:          "Model"
  vehicleProductionYear: "Production year"
  insuranceMandatory:    "Insurance mandatory"
  insuranceCompany:      "Insurance company"
  insuredUntil:          "Insured until"
  assetInfo:             "Asset info"
  internalComment:       "Internal comments"
  sellerId:              "Seller"
  sellerContactName:     "Seller contact name"
  sellerPhone:           "Seller contact phone"
  sellerEmail:           "Seller contact e-mail"
  warrantyArea:          "Area"
  warrantyOwnerName:               "Owner name"
  postalСode:            "Postal code"
  soldDate:              "Sold date"
  assetSoldValue:        "Asset sold value"
  appraisalPartner:      "Appraisal partner"
  reportingType:         "Reporting type"
  valueType:             "Value type"
  valuationType:         "Valuation type"
  locationCode:          "Location code"
  assetStatus:           "Asset status"
  createCustomer:                  "Create customer"
  existingCustomerPlaceholder:     "Select customer"
  appraisalReport:                 "Appraisal report"
et:
  description:           "Description"
  assetGroup:            "Asset group"
  value:                 "Value"
  valueDate:             "Value date"
  allocatedValue:        "Allocated value"
  vehicleBrand:          "Brand"
  vehicleModel:          "Model"
  vehicleProductionYear: "Production year"
  insuranceMandatory:    "Insurance mandatory"
  insuranceCompany:      "Insurance company"
  insuredUntil:          "Insured until"
  assetInfo:             "Asset info"
  internalComment:       "Internal comments"
  sellerId:              "Seller"
  sellerContactName:     "Seller contact name"
  sellerPhone:           "Seller contact phone"
  sellerEmail:           "Seller contact e-mail"
  warrantyArea:          "Area"
  warrantyOwnerName:               "Owner name"
  postalСode:            "Postal code"
  soldDate:              "Sold date"
  assetSoldValue:        "Asset sold value"
  appraisalPartner:      "Appraisal partner"
  reportingType:         "Reporting type"
  valueType:             "Value type"
  valuationType:         "Valuation type"
  locationCode:          "Location code"
  assetStatus:           "Asset status"
  createCustomer:                  "Create customer"
  existingCustomerPlaceholder:     "Select customer"
  appraisalReport:                 "Appraisal report"
ru:
  description:           "Description"
  assetGroup:            "Asset group"
  value:                 "Value"
  valueDate:             "Value date"
  allocatedValue:        "Allocated value"
  vehicleBrand:          "Brand"
  vehicleModel:          "Model"
  vehicleProductionYear: "Production year"
  insuranceMandatory:    "Insurance mandatory"
  insuranceCompany:      "Insurance company"
  insuredUntil:          "Insured until"
  assetInfo:             "Asset info"
  internalComment:       "Internal comments"
  sellerId:              "Seller"
  sellerContactName:     "Seller contact name"
  sellerPhone:           "Seller contact phone"
  sellerEmail:           "Seller contact e-mail"
  warrantyArea:          "Area"
  warrantyOwnerName:               "Owner name"
  postalСode:            "Postal code"
  soldDate:              "Sold date"
  assetSoldValue:        "Asset sold value"
  appraisalPartner:      "Appraisal partner"
  reportingType:         "Reporting type"
  valueType:             "Value type"
  valuationType:         "Valuation type"
  locationCode:          "Location code"
  assetStatus:           "Asset status"
  createCustomer:                  "Create customer"
  existingCustomerPlaceholder:     "Select customer"
  appraisalReport:                 "Appraisal report"
</i18n>
